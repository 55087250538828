/*
 * Power HR RPE conversion functions
 */
const filledModel = require('./filledModel');


const RPE_POWER = [0, 0.4, 0.6, 0.8, 0.9, 0.97, 1.02, 1.1, 1.3, 1.5];
const RPE_HR = [0, 0.52, 0.7, 0.84, 0.94, 0.98, 1.02, 1.1, 1.3, 1.5];
//RPE:
//       Power %ftp           HR %threshold
//0: 0                          0
//1: ]0, 40%]                   ]0, 52%]
//2: ]40%, 60%]                 ]52%, 70%]
//3: ]60%, 80%]                 ]70%, 84%]
//4: ]80%, 90%]                 ]84%, 94%]
//5: ]90%, 97%]                 ]94%, 98%]
//6: ]97%, 102%]                ]98%, 102%]
//7: ]102%, 110%]               ]102%, 110%]
//8: ]110%, 130%]               ]110%, 130%]
//9: ]130%, 150%]               ]130%, 150%]
//10 >150%                      >150%

//conversion to rpe is index of value in defined array
function toRPE(frac, array){
  var rpe = 0;
  for(rpe = 0; rpe<array.length; rpe++)
    if(array[rpe] > frac)
      break;

  return rpe;
}

function powerToRPE(power, model){
  model = filledModel(model);

  var frac = power/model.threshold_power;
  return toRPE(frac, RPE_POWER);
}

function heartRateToRPE(hr, model){
  model = filledModel(model);

  var frac = hr/model.threshold_heart_rate;
  return toRPE(frac, RPE_HR);
}

function RPEToPower(rpe, model){
  model = filledModel(model);

  if(rpe <= 0)
    return 0;
  if(rpe >= 10)
    return model.max_power;
  return ((RPE_POWER[rpe-1]+RPE_POWER[rpe])/2) * model.threshold_power; //get middle of zone rpe value
}

function RPEToHeartRate(rpe, model){
  model = filledModel(model);

  if(rpe <= 0)
    return model.resting_heart_rate;
  if(rpe >= 10)
    return model.max_heart_rate;
  var hr = ((RPE_HR[rpe-1]+RPE_HR[rpe])/2) * model.threshold_heart_rate; //get middle of zone rpe value
  return Math.max(Math.min(hr, model.max_heart_rate), model.resting_heart_rate);
}

//Conversion value for the first 5 power zones to the 5 hr zones
const HR_POWER = [
  [0, 0.55, 'RESTING', 0.68],
  [0.55, 0.75, 0.68, 0.83],
  [0.75, 0.9, 0.84, 0.94],
  [0.9, 1.05, 0.95, 1.05],
  [1.06, 1.2, 1.06, 'MAX']
];

function powerToHeartRate(power, model, hrCanConvertToRpe = true){
  model = filledModel(model);

  var powerFrac = power/model.threshold_power;
  if(powerFrac > 1.2){ //For power above 120% ftp (zone 6 and 7), convert to RPE instead if possible
    if(hrCanConvertToRpe)
      return { target_unit: 'rpe', value: powerToRPE(power, model) };
    else //If not possible then target will be max heart rate
      return { target_unit: 'heart_rate', value: model.max_heart_rate };
  }

  var zone = 0; //Find power zone
  for(zone = 0; zone < HR_POWER.length-1; zone++)
    if(powerFrac <= HR_POWER[zone][1])
      break;

  //power and hr start and end value for the target zone
  var powerStart = HR_POWER[zone][0] * model.threshold_power;
  var powerEnd = HR_POWER[zone][1] * model.threshold_power;
  var hrStart = HR_POWER[zone][2] === 'RESTING' ? (model.resting_heart_rate || 0) : Math.max(model.threshold_heart_rate * HR_POWER[zone][2], model.resting_heart_rate || 0);
  var hrEnd = HR_POWER[zone][3] === 'MAX' ? model.max_heart_rate : Math.min(model.threshold_heart_rate * HR_POWER[zone][3], model.max_heart_rate);

  //Get position of power value inside the power zone as a frac and convert position inside hr zone
  var powerDiff = powerEnd - powerStart;
  var hrDiff = hrEnd - hrStart;
  var frac = (power - powerStart)/powerDiff;
  var hr = Math.round(frac*hrDiff + hrStart);

  return { target_unit: 'heart_rate', value: hr };
}

module.exports = {
  powerToRPE,
  heartRateToRPE,
  RPEToPower,
  RPEToHeartRate,
  powerToHeartRate,
};
