/*
 * Utility functions for zonew
 */


//Power zones from ftp
function powerZones(ftp){
  return [Math.round(ftp*0.55), Math.round(ftp*0.75), Math.round(ftp*0.9), Math.round(ftp*1.05), Math.round(ftp*1.2), Math.round(ftp*1.5)];
}
//Hr zones from hr threshold
function hrZones(fthr){
  return [Math.round(fthr*0.68),  Math.round(fthr*0.83),  Math.round(fthr*0.95), Math.round(fthr*1.05)];
}

//Get zone for value
function zoneOfValue(value, zones){
  let i=0;
  while(i < zones.length){ //Just find the index that match the value given in the array
    if(value <= zones[i])
      break;
    i++;
  }
  return i;
}

function powerZoneOfValue(value, model){
  return zoneOfValue(value, powerZones(model.threshold_power));
}

function hrZoneOfValue(value, model){
  return zoneOfValue(value, hrZones(model.threshold_heart_rate));
}

module.exports = {
  powerZones,
  hrZones,
  zoneOfValue,
  powerZoneOfValue,
  hrZoneOfValue,
};
