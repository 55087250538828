/*
 * Utility functions used on activities, mainly for generating data from files when needed
 */
const dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));
const zones = require('../zones');


//Get the best values from the power curve for the duration asked
function getBestCurveValueForTime(curve, duration){
  var nextInc = [[2,60], [5, 300], [10, 600], [30, 1800], [60, 3600]];
  var adds = [60, 120, 60, 120, 60];
  var index = 0;

  //Find duration index
  var i;
  for(i=0; i<nextInc.length; i++){
    if(duration >= nextInc[i][1]){
      index += adds[i];
    }else{
      break;
    }
  }
  i--;
  if(i<0)
    index = duration;
  else
    index+= Math.ceil((duration - nextInc[i][1])/nextInc[i][0]);

  if(index > 0)
    index --; //Index is duration - 1

  if(curve.length > index) //And if the curve contain it return it
    return curve[index];
  return 0;
}

//Calc power trends of an activity (best power curve value for a set of duration)
//By default, 5s 15s 30s 1min 2min 5min 10min 20min 30min 1h 2h
function calcPowerTrends(activityData, durations = [5,15,30,60,120,300,600,1200,1800,3600,7200]){
  var trends = {};
  durations.forEach(duration =>{
    trends[duration.toString()] = getBestCurveValueForTime(activityData.power_curve, duration);
  });
  return trends;
}

//Calc curve from  data
function curveFromData(data, pause){
  if(data.length == 0)
    return [];

  if(pause.length > 0) //If there are pause, duplicate data array as it will be modified
    data = data.slice(0);

  for(var i=pause.length-2; i>=0; i-=2){ //add 0 for each second of pause before calculating curve
    data.splice(pause[i], 0, ...Array(pause[i+1]).fill(0));
  }

  var nextInc = [[2,60], [5, 300], [10, 600], [30, 1800], [60, 3600]]; //inc: 1s; 2s > 1min; 5s > 5min; 10s > 10min; 30s > 30min; 1min > 1h
  var nextIncZone = 0;
  var inc = 1;
  var curve = [];
  //Find best power for each durations
  for(let i=1; i<data.length; i+=inc){  //Iterate on each duration below activity total duration
    if(nextIncZone < nextInc.length && i >= nextInc[nextIncZone][1]){ //Update increment if the duration is in a next increment zone
      inc = nextInc[nextIncZone][0];
      nextIncZone++;
    }
    //For each point in power data, calculate average value (power or heart rate) for the current duration and keep the biggest
    let sum = 0;
    for(let j=0; j<i; j++)
      sum += data[j];
    let max = Math.round(sum/i); //Init first value as the average value of duration from point 0
    for(let j=0; j+i<data.length; j++){ //Calculate each other values
      sum -= data[j];
      sum += data[j+i];
      let current = Math.round(sum/i);
      max = Math.max(current, max); //And only keep the best
    }
    curve.push(max); //and push it in the curve data
  }
  return curve;
}

function powerCurveFromData(powerData, pauses){ return curveFromData(powerData, pauses); }
function heartRateCurveFromData(powerData, pauses){ return curveFromData(powerData, []); /* pause not taken into consideration for hr curve*/ }

//Calculate time spent in zone from power/heart rate data
function zonesTimeFromData(data, zones){
  var zonesTime = new Array(zones.length+1).fill(0);
  data.forEach(nb => {
    let i=0;
    while(i < zones.length){
      if(nb <= zones[i])
        break;
      i++;
    }
    zonesTime[i] += 1;
  });
  return zonesTime;
}


function zonesTimeFromPowerData(powerData, ftp){
  return zonesTimeFromData(powerData, zones.powerZones(ftp));
}

function zonesTimeFromHrData(hrData, fthr){
  return zonesTimeFromData(hrData, zones.hrZones(fthr));
}


//Calc trimp data for activity
function calcTrimp(hrData, lastModel, userInfos){
  var mhr = lastModel.max_heart_rate;
  var rhr = lastModel.resting_heart_rate;
  if(!mhr || !rhr) //if no max or resting heart rate, no trimp data can be calculated
    return 0;
  var hrr = mhr - rhr; //heart rate reserve
  const constant = (userInfos && userInfos.sex === 'female') ? 1.67 : 1.92; //Constant is 1.67 for females and 1.92 for males

  //Calc hr histogram from hr data
  var histogram = {};
  hrData.forEach(hr => {
    if(!histogram[hr])
      histogram[hr] = 0;
    histogram[hr]++;
  });

  var trimp = 0;
  for(let [hr, time] of Object.entries(histogram)){ //for each hr value
    var hrfrac = (hr-rhr)/hrr; //calc hr frac of heart rate reserve
    trimp+= (time/60) * hrfrac * 0.64 * Math.exp(constant * hrfrac); //formula for trimp to add for each hr
  }
  return Math.round(trimp); //round and return
}

//Calc accumulated power (sum of work done since the beginning for each point)
function calcAccumulatedPower(powers){
  var result = [];
  var acc = 0;
  powers.forEach(power => {
    acc += power;
    result.push(acc);
  });
  return result;
}

//Calc normalized power
function calcNPower(powerData){
  var npowerData = [];

  //30sec rolling average from the beginning of the data
  var sum = 0;
  const NBSIDE = 12; //moving average with a window of 25 (12+1+12)
  var nb = NBSIDE; //NBSIDE is half the size of the window. nb is current number of points in the sum
  for(let i=0; i<NBSIDE; i++){ //Sum the first powers values to ge a starting sum
    sum+= powerData[i];
  }
  for(let i=0; i<powerData.length; i++){ //for each point
    npowerData[i] = sum/nb; //moving average of point i is sum/window side
    //Move the window to the right by 1s
    if(i > NBSIDE){ //If the lest side of the window is after the first point
      sum-= powerData[i-NBSIDE]; //Remove the most left point from the sum
      nb--;
    }
    if(i+NBSIDE < powerData.length){ //If the right side of the window is before the last point
      sum+= powerData[i+NBSIDE]; //Add the point at the right of the window to the sum
      nb++;
    }
  }

  //Raise values to the fourth power
  for(let i=0; i<npowerData.length; i++)
    npowerData[i] = Math.pow(npowerData[i],4);

  //Average
  var avg = 0;
  for(let i=0; i<npowerData.length; i++)
    avg+= npowerData[i];
  avg/= npowerData.length;

  //Fourth root of average
  var result = Math.round(Math.pow(avg, 1/4));

  return result;
}

//Calc distance between coordinates
function distanceBetweenCoords(lat1, lon1, lat2, lon2){
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI/180; // φ, λ in radians
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c;
}

//Calc distance done for each points with a coordinate
function calcDistancesFromCoord(lat, lon){
  if(lat.length == 0 || lon.length == 0)
    return [];

  var distances = [0];
  for(var i=1; i<Math.min(lat.length, lon.length); i++){
    var newDist = distanceBetweenCoords(lat[i-1], lon[i-1], lat[i], lon[i])/1000;
    newDist = newDist > 1 ? 0 : newDist;
    distances.push(distances[distances.length-1]+newDist);
  }

  return distances;
}

//Calc power hr ratio (poweravg/hravg) between 2 indexes
function calcPowerHrRatio(power, hr, start=0, end=null){
  if(end == null)
    end = Math.min(hr.length, power.length);

  if(start >= end || end > Math.min(hr.length, power.length))
    return 0;

  var hravg = 0;
  var poweravg = 0;
  for(var i=start; i<end; i++){
    hravg += hr[i];
    poweravg += power[i];
  }
  hravg /= (end-start);
  poweravg /= (end-start);

  return poweravg / hravg;
}

//Decoupling is % difference of power hr ratio of first half and second half
function calcDecoupling(power, hr){
  const length = Math.min(hr.length, power.length);
  const half = Math.ceil(length/2);
  if(length-half == 0)
    return 0;

  var ratio1 = calcPowerHrRatio(power, hr, 0, half);
  var ratio2 = calcPowerHrRatio(power, hr, half, length);
  return Math.round(((ratio1-ratio2)/ratio1)*10000)/100; //To percent with only 2 decimal digit
}

//Intensity is normalized power/ ftp
function calcIntensity(activity, thresholdPower){
  return activity.data.normalized_power/thresholdPower;
}

//TSS is activity duration in minutes * intensity square
function calcTss(activity, intensity){
  return Math.round((activity.duration/36)*intensity*intensity);
}

//Calc hrss of activity (with trimp data)
function calcHrss(activity, lastModel){
  if(lastModel && lastModel.threshold_heart_rate && activity.data.trimp){
    const constant = 1.92;
    var threshold_frac = (lastModel.threshold_heart_rate-lastModel.resting_heart_rate)/(lastModel.max_heart_rate-lastModel.resting_heart_rate)
    var threshold_trimp = 60 * threshold_frac * 0.64 * Math.exp(constant * threshold_frac);
    return Math.round(activity.data.trimp*100/threshold_trimp);
  }
  return 0;
}

//Load of activity
//Tss if activity has power, hrss otherwise
function calcLoad(activity, lastModel){
  var thresholdPower =  (lastModel && lastModel.threshold_power) || 0;
  if(thresholdPower){
    activity.data.intensity = calcIntensity(activity, thresholdPower) || 0;
    activity.data.tss = calcTss(activity, activity.data.intensity) || 0;
  }
  activity.data.hrss = calcHrss(activity, lastModel) || 0;
  activity.data.load = activity.data.tss || activity.data.hrss || 0;
}

//Calc all needed activity data (with utility function above)
function calcGeneralActivityData(data, activityData, lastModel, userInfos){
  if(!activityData)
    return;

  if(activityData.heart_rate.length > 0 && activityData.power.length > 0){
    data.power_hr_ratio = Math.round(calcPowerHrRatio(activityData.power, activityData.heart_rate)*1000)/1000; //only 4 decimal digit
    data.aerobic_decoupling = calcDecoupling(activityData.power, activityData.heart_rate);
  }



  if(activityData.heart_rate.length > 0 && !!lastModel && !!lastModel.threshold_heart_rate)
    data.time_hr_zones = zonesTimeFromHrData(activityData.heart_rate, lastModel.threshold_heart_rate);

  if(activityData.heart_rate.length > 0 && !!lastModel && !!lastModel.max_heart_rate && !!lastModel.resting_heart_rate)
    data.trimp = calcTrimp(activityData.heart_rate, lastModel, userInfos);


  if(activityData.power.length > 0 && !!lastModel && !!lastModel.threshold_power)
      data.time_power_zones = zonesTimeFromPowerData(activityData.power, lastModel.threshold_power);

  if(activityData.power_curve.length > 0)
    data.power_trends = calcPowerTrends(activityData);
}


function toLocalDate(date){
  return dayjs(date).utc(true).toDate();
}


function pad(num, len) {return (Array(len).join("0") + num).slice(-len);}
//'YYYY-MM-DD'
function dateFormat(date){
  return date.getUTCFullYear()+'-'+pad(date.getUTCMonth()+1, 2)+'-'+pad(date.getUTCDate());
}

module.exports = {
  getBestCurveValueForTime,
  calcPowerTrends,
  curveFromData,
  powerCurveFromData,
  heartRateCurveFromData,
  zonesTimeFromData,
  zonesTimeFromPowerData,
  zonesTimeFromHrData,
  calcTrimp,
  calcAccumulatedPower,
  calcNPower,
  distanceBetweenCoords,
  calcDistancesFromCoord,
  calcPowerHrRatio,
  calcDecoupling,
  calcIntensity,
  calcTss,
  calcHrss,
  calcLoad,
  calcGeneralActivityData,
  toLocalDate,
  dateFormat,
};
