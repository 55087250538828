/*
 * Fill model with default value when they aren't defined
 */
const defaultModel = {
  threshold_power: 200,
  anaerobic_capacity: 15000,
  max_power: 1000,
  resting_heart_rate: 60,
  threshold_heart_rate: 160,
  max_heart_rate: 190,
};

module.exports = function(model){
  var copy = Object.assign({}, defaultModel); //copy default model
  if(model){
    for(let key in copy) //And replace default model keys values for each that are defined in model
      if(model[key])
        copy[key] = model[key];
  }
  return copy;
};

module.exports.defaultModel = defaultModel;
