/*
 * Calc Power Model from a power curve
 */

 //Time for curve index
function getTimeForIndex(index){
  index++;
  if(index < 60)
    return index;
  index-=60;
  if(index < 120)
    return 60+index*2;
  index-=120;
  if(index < 60)
    return 300+index*5;
  index -= 60;
  if(index < 120)
    return 600+index*10;
  index-=120;
  if(index < 60)
    return 1800+index*30;
  index-=60;
  return 3600+index*60;
}

//Curve index for time_day
function getIndexForTime(duration){
  var nextInc = [[2, 60], [5, 300], [10, 600], [30, 1800], [60, 3600]]; //each increment that are used at each threshold : 1 inc until 60s reached, then 2inc until 300s...
  var adds = [60, 120, 60, 120, 60]; //Number of values between each threshold
  var index = 0; //Index to find

  var i;
  //Find the biggest threshold that is just below the index and update index value accordingly
  for(i=0; i<nextInc.length; i++){
    if(duration >= nextInc[i][1]){
      index += adds[i];
    }else{
      break;
    }
  }
  i--;
  if(i<0) //If no threshold below the index (duration < 60) then index = duration
    index = duration;
  else
    index+= Math.ceil((duration - nextInc[i][1])/nextInc[i][0]); //Index = threshold value + remaining duration divided by increment step

  return index;
}


class PowerModel{
  //Power model is defined by pmax (max 1sec power), criticalPower (ftp), anaerobic capacity and stamina (other parameters not yet used)
  constructor(pmax, anaerobicCapacity, criticalPower, timeToExhaustion, timeToFullAerobicUse, timeToFullAnaerobicUse, stamina, date = new Date()){
    this.pmax = pmax;
    this.anaerobicCapacity = anaerobicCapacity;
    this.criticalPower = criticalPower;
    this.timeToExhaustion = timeToExhaustion;
    this.timeToFullAerobicUse = timeToFullAerobicUse;
    this.timeToFullAnaerobicUse = timeToFullAnaerobicUse;
    this.stamina = stamina;
    this.date = date;
  }

  //Calculate a power model from a power curve
  static fromPowerCurve(powerCurve, date = new Date()){
    var pmax = powerCurve[0]; //pmax is best 1s power
    var anaerobicCapacity = 0;
    var criticalPower = 0;
    var tte = 3600;
    var stamina = 0;

    //Find critical power and anaerobic capacity
    //Find 2 points:
    //One that allow the greater anaerobic capacity possible
    //While the second will maximize ftp according to the first chosen point
    //The first point will be searched between 1min and 10min
    //The second point will be between at least 1.5* the first point and 1hour
    for(let i=60; i<240; i++){
      //for each possible first points
      let p1 = powerCurve[i];
      let t1 = getTimeForIndex(i);

      let cp = 0;
      let power2, time2;
      for(let j=getIndexForTime(t1*1.5); j<420; j++){
        //find a possible second point that maximize ftp
        let p2 = powerCurve[j];
        let t2 = getTimeForIndex(j);

        let ac = ((t1*p1-t1*p2)/(1-t1/t2));
        let newCp = (p2-ac/t2);
        if(newCp > cp){
          cp = newCp;
          power2 = p2;
          time2 = t2;
        }
      }
      //Store the first point that maximized anaerobic capacity while maximizing ftp for the second point
      let ac = Math.round((p1-cp)*t1);
      if(ac > anaerobicCapacity){
        anaerobicCapacity = Math.round(ac);
        criticalPower = Math.round(cp);
      }
    }

    //Calc stamina. Find a decreasing % of ftp for each hour that match the rest of the curve the best
    for(let i=getIndexForTime(tte)+10; i<powerCurve.length; i++){
      let p = powerCurve[i];
      let t = getTimeForIndex(i);
      let stam = Math.pow((p-anaerobicCapacity/t)/criticalPower, 1.0/((t-tte)/3600));
      stamina = Math.max(stamina, stam);
    }

    //console.log('Model: pmax: ', pmax, ' AC: ', anaerobicCapacity, ' CP: ', criticalPower, ' Stamina: ', stamina);

    return new PowerModel(pmax, anaerobicCapacity, criticalPower, tte, 120, 60, stamina, date);
  }

  //Build a power curve from the power model
  buildPowerCurve(maxTime){
    var powerCurve = [];

    //For each point of the curve, calc a point according to the 4 parameters
    for(var i=0; getTimeForIndex(i) <= maxTime; i++){
      var t = getTimeForIndex(i);
      var data = 0;
      var tau1 = this.anaerobicCapacity/(this.pmax);
      var anaerobic = this.anaerobicCapacity/t * (1-Math.exp(-t/tau1)); //anaerobic contribution
      var aerobic = this.criticalPower * (1-Math.exp(-t/this.timeToFullAerobicUse*10)); //aerobic contribution
      if(t > this.timeToExhaustion) //For time above one hour, take stamina into account
        aerobic*= Math.pow(this.stamina, (t-this.timeToExhaustion)/3600);
      data = Math.min(aerobic + anaerobic, this.pmax); //Point is aerobic + anaerobic contribution (maxed at pmax)

      powerCurve.push(Math.round(data));
    }
    return powerCurve;
  }

  //Power model to a simple model object
  toData(){
    return {
      date: this.date,
      threshold_power: this.criticalPower,
      anaerobic_capacity: this.anaerobicCapacity,
      max_power: this.pmax,
      stamina: this.stamina
    };
  }

  //Estimate power at a duration from the power model
  //Same method than power curve from model function but for a single point
  static estimatePowerAtDuration(model, duration){
    const timeToFullAerobicUse = 60;
    const timeToExhaustion = 3600;

    var power = 0;

    var tau1 = model.anaerobic_capacity/(model.max_power);
    var anaerobic = model.anaerobic_capacity/duration * (1-Math.exp(-duration/tau1));
    var aerobic = model.threshold_power * (1-Math.exp(-duration/timeToFullAerobicUse*10));
    if(duration > timeToExhaustion)
      aerobic*= Math.pow(this.stamina, (duration-timeToExhaustion)/3600);
    power = Math.round(Math.min(aerobic + anaerobic, model.max_power));

    return power;
  }

  //Estimate MAP (maximum aerobic power) from model
  //Currently the 5min power
  static modelMAP(model){
    if(!model.threshold_power || !model.anaerobic_capacity || !model.max_power)
      return 0;
    return this.estimatePowerAtDuration(model, 300) || 0;
  }
}

module.exports = PowerModel;
