<template>
  <div class="workout-viewer" ref="root"/>
</template>

<script>
import * as d3 from 'd3';
const workoutUtil = require('@/../shared/workout/workoutUtil.js');


export default{
  name: 'workout-viewer',
  props: {
    workout: Object,
    showAxis: {
      type: Boolean,
      default: false,
    },
    showToolTip: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      width: 0,
      height: 0,
      root: null,
      svg: null,
      model: {}
    };
  },
  computed: {
    steps(){ return workoutUtil.toSimpleSteps(this.workout.steps); },
    domainWidth(){ return this.width - this.margin.left - this.margin.right; },
    domainHeight(){ return this.height - this.margin.top - this.margin.bottom; },
    margin(){
      if(this.showAxis)
        return { top: 0, left: 20, right: 20, bottom: 20 };
      return { top: 0, left: 0, right: 0, bottom: 0 };
    },
  },
  methods: {
    setSize(){
      if(this.$refs.root){
        this.width = this.$refs.root.clientWidth;
        this.height = this.$refs.root.clientHeight;
      }
    },

    createView(){
      var self = this;

      this.setSize();

      this.root = d3.select(this.$refs.root).append('svg')
        .attr('class', 'svg');

      this.svg = this.root.append('g')
        .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');


      this.axisXGraphic = this.svg.append('g');

      this.updateView();

      if(this.showToolTip){
        this.createToolTip();
        this.root.on('touchmove mousemove', function(event){
          self.onMouseMove(event);
        });
        this.root.on('touchleave mouseout', function(event){
          self.onMouseOut(event);
        });
      }
    },

    updateView(){
      this.setSize();

      this.root.attr('width', this.width)
        .attr('height', this.height);

      this.axisX = d3.scaleLinear().range([0, this.domainWidth]);
      this.axisY = d3.scalePow().exponent(1).range([0, this.domainHeight]);

      this.axisXGraphic.attr('transform', 'translate(0, ' + this.domainHeight.toString() + ')');

      this.drawView();
    },

    drawView(){
      var stepsData = [];
      var currentDuration = 0;
      var maxTarget = 0;
      this.steps.forEach(step => {
        let value = workoutUtil.powerValueFromStep(step, this.model);
        stepsData.push({
          start: currentDuration,
          duration: step.duration,
          target: step.target,
          value: value,
          zone: workoutUtil.stepPowerZone(step, this.model)+1,
        });
        currentDuration += step.duration;
        maxTarget = Math.max(maxTarget, step.target);
      });

      this.axisX.domain([0, this.workout.duration]);
      this.axisY.domain([0, this.model.max_power || 1000]);

      if(this.showAxis){
        this.axisXGraphic.call(d3.axisBottom(this.axisX).tickFormat(nb => {
          var formatSecond = d3.utcFormat('%S'), formatMinute = d3.utcFormat('%M:%S'), formatHour = d3.utcFormat('%H:%M');
          return (nb < 3600 ? (nb < 60  ? formatSecond : formatMinute) : formatHour)(nb*1000);
        }));
      }


      this.blocks = this.svg.selectAll('.blocks')
        .data(stepsData);

      this.blocks.exit()
        .remove();


      this.blocks.enter()
        .append('rect')
        .merge(this.blocks)
        .attr('class', d => 'blocks zone-' + d.zone)
        .attr('x', d => this.axisX(d.start))
        .attr('y', d => this.domainHeight-this.axisY(d.value))
        .attr('width', d => this.axisX(d.duration))
        .attr('height', d => this.axisY(d.value));
    },



    createToolTip(){
      this.tooltip = this.svg.append('g')
        .attr('id', 'tooltip');
        //.style('display', 'none');

      //circle
      this.tooltip.append('circle')
        .attr('fill', '#CCE5F6')
        .attr('r', 10);

      this.tooltip.append('circle')
        .attr('fill', '#3498db')
        .attr('stroke', '#fff')
        .attr('stroke-width', '1.5px')
        .attr('r', 4);

      var height = 60;

      //tooltip
      this.tooltip.append('polyline')
        .attr('points','0,0 0,'+height+' 70,'+height+' 75,'+(height+5)+' 80,'+height+' 150,'+height+' 150,0 0,0')
        .style('fill', '#fafafa')
        .style('stroke','#3498db')
        .style('opacity','0.9')
        .style('stroke-width','1')
        .attr('transform', 'translate(-75, -'+(height+5)+')');

      // Parent text element
      this.valueText = this.tooltip.append('text')
        .style('font-size', '13px')
        .style('font-family', 'Segoe UI')
        .style('color', '#333333')
        .style('fill', '#333333')
        .attr('transform', 'translate(-50, -'+(height-10)+')');

      //Text content
      this.valueText.append('tspan')
        .text(this.$t('metrics.duration')+': ');

      this.valueText.append('tspan')
        .attr('id', 'tooltip-duration')
        .style('font-weight', 'bold');

      this.valueText.append('tspan')
        .attr('x', '0')
        .attr('dy', '1.2em')
        .text(this.$t('workout.target')+': ');

      this.valueText.append('tspan')
        .attr('id', 'tooltip-power')
        .style('font-weight', 'bold');

      this.valueText.append('tspan')
        .attr('class', 'cadence')
        .attr('x', '0')
        .attr('dy', '1.2em')
        .text(this.$t('metrics.cadence')+': ');

      this.valueText.append('tspan')
        .attr('class', 'cadence')
        .attr('id', 'tooltip-cadence')
        .style('font-weight', 'bold');

    },

    onMouseMove(event){
      var mouse_x = d3.pointer(event)[0] - this.margin.left;
      var mouse_y = d3.pointer(event)[1] - this.margin.top;

      if(mouse_x < 0 || mouse_x > this.width || mouse_y < 0 || mouse_y > this.height)
        return;

      this.tooltip.style('display', 'block');

      var selectedTime = this.axisX.invert(mouse_x);
      var currentDuration = 0;
      var i=0;
      for(let step of this.steps){
        var nextDuration = currentDuration + step.duration;
        if(selectedTime >= currentDuration && selectedTime <= nextDuration)
          break;
        currentDuration = nextDuration;
        i++;
      }
      if(i >= this.steps.length)
        return;

      var step = this.steps[i];
      var xpos = currentDuration + step.duration/2;

      var displayValue = workoutUtil.powerValueFromStep(step, this.model);
      var convertedStep = workoutUtil.convertStepWithModel(step, this.model, this.workout.target_unit);
      const units = { power: 'W', heart_rate: this.$t('metrics.bpm'), rpe: this.$t('metrics.rpe') };

      var unit =  units[convertedStep.target_unit];
      var value = convertedStep.value;

      let yPos = this.domainHeight - Math.min(this.axisY(displayValue), 230); //Calculate ypos of tooltip. Max value of 230 above to not get out of screen
      this.tooltip.attr('transform', 'translate(' + this.axisX(xpos) + ',' + yPos + ')');

      d3.select('#tooltip-duration')
          .text(this.utimeToString(step.duration));
      d3.select('#tooltip-power')
          .text(value + unit);

      if(step.cadence_target)
        d3.select('#tooltip-cadence')
          .text(step.cadence_target + 'rpm');
      d3.selectAll('.cadence')
        .style('display', step.cadence_target ? 'block' : 'none');
    },

    onMouseOut(){
      this.tooltip.style('display', 'hidden');
    }
  },

  mounted(){
    this.model = this.ugetSetModel(this.workout.date || this.LocalDate.now());
    this.createView();

    //On root element resize, update view
    //TODO: Resize function instead of redrawing (faster, more efficient)
    var ro = new ResizeObserver(entries => {
      this.updateView();
    });
    ro.observe(this.$refs.root);
  },
  watch: {
    steps: {
      handler(){
        this.updateView();
      },
      deep: true
    }
  }
}

</script>

<style lang="scss">


.workout-viewer{
  margin: auto;
  border: solid 1px gray;
}

.workout-viewer
  .zone-1{ fill: $chart-blue; }
  .zone-2{ fill: $chart-green; }
  .zone-3{ fill: $chart-yellow; }
  .zone-4{ fill: $chart-orange; }
  .zone-5{ fill: $chart-red; }
  .zone-6{ fill: $chart-red-dark; }
  .zone-7{ fill: $chart-purple; }
</style>
