<!-- Duration Picker -->
<!-- Component that allow user to pick durations value (hours:minutes:seconds) with a picker interface -->
<!-- Use buefy picker html code -->
<template>
  <div class="durationpicker">
    <div class="timepicker control">
      <div class="dropdown dropdown-menu-animation is-inline is-active">
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <div tabindex="0" class="dropdown-item">
              <div class="field">
                <div class="field-body">
                  <div class="field is-grouped is-grouped-centered">
                    <div class="control" v-if="showHours">
                      <span class="select m-0">
                        <select v-model.number="hours" class="p-0 w-2" :aria-label="$t('general.hours')">
                          <option v-for="(v, i) in (maxHours+1)" :key="i" :value="i"> {{ $pad(i, 2) }} </option>
                        </select>
                      </span>
                    </div>
                    <span class="control is-colon" v-if="showHours">:</span>
                    <div class="control" v-if="showMinutes">
                      <span class="select m-0">
                        <select v-model.number="minutes" class="p-0 w-2" :aria-label="$t('general.minutes')">
                          <template v-for="(v, i) in 60">
                            <option :key="i" :value="i" v-if="i%minutesIncrement === 0"> {{ $pad(i, 2) }} </option>
                          </template>
                        </select>
                      </span>
                    </div>
                    <span class="control is-colon" v-if="showMinutes&&showSeconds">:</span>
                    <div class="control" v-if="showSeconds">
                      <span class="select m-0">
                        <select v-model.number="seconds" class="p-0 w-2" :aria-label="$t('general.seconds')">
                          <template v-for="(v, i) in 60">
                            <option :key="i" :value="i" v-if="i%secondsIncrement === 0"> {{ $pad(i, 2) }} </option>
                          </template>
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'duration-picker',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    showHours: {
      type: Boolean,
      default: true,
    },
    showMinutes: {
      type: Boolean,
      default: true,
    },
    showSeconds: {
      type: Boolean,
      default: false,
    },
    maxHours: {
      type: Number,
      default: 24,
    },
    //Minutes and seconds increments allow to display less possibles values to pick to the user (eg: inc of 15 to show only quarters)
    minutesIncrement: {
      type: Number,
      default: 1,
    },
    secondsIncrement: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  methods: {
    updateValue(){
      this.$emit('input', (this.hours*3600 + this.minutes*60 + this.seconds) || 0);
    },
    setValue(){
      this.hours = this.showHours ? Math.floor(this.value/3600) : 0;
      this.minutes = this.showMinutes ?
        (this.showHours ? Math.floor((this.value%3600)/60) : Math.floor(this.value/60))
        : 0;
      this.seconds = this.showSeconds ?
        (this.showMinutes ? this.value%60 : this.value)
        : 0;
    }
  },
  mounted(){
    this.setValue();
  },
  watch: {
    value(){
      this.setValue();
    },
    hours(){
      this.updateValue();
    },
    minutes(){
      this.updateValue();
    },
    seconds(){
      this.updateValue();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
