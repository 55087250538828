/*
 * Utility functions for workouts
 */
const activityUtil = require('../activity/activityUtil');
const zones = require('../zones');
const intensityUnitsConvert = require('./intensityUnitsConvert');
const filledModel = require('./filledModel');
var PowerModel = require('../powerModel.js');


//Get target value from step
function valueFromStep(step, model){
  if(step.target_unit === 'power'){
    return powerValueFromStep(step, model);
  }else if(step.target_unit === 'heart_rate'){
    return heartRateValueFromStep(step, model);
  }else{
    return rpeValueFromStep(step, model);
  }
}

//Get target value from step as heart rate (convert if needed)
function heartRateValueFromStep(step, model){
  model = filledModel(model); //Fill model with default value to help conversion in case power or hr is empty
  if(step.target_unit === 'power'){ //if target is power
    switch(step.target_type){ //convert to heart rate
      case 'threshold': return Math.round(step.target*model.threshold_heart_rate/100);
      case 'max': return Math.round(step.target*model.max_heart_rate/100);
      case 'anaerobic': return model.threshold_heart_rate + Math.round(step.target*(model.max_heart_rate-model.threshold_heart_rate)/100);
      case 'map': return Math.round(step.target*model.max_heart_rate/100);
    }
  }else if(step.target_unit === 'heart_rate'){
    switch(step.target_type){
      case 'threshold': return Math.round(step.target*model.threshold_heart_rate/100);
      case 'max': return Math.round(step.target*model.max_heart_rate/100);
      //case 'anaerobic': return ;
    }
  }else{ //if RPE convert to heart rate
    return intensityUnitsConvert.RPEToHeartRate(step.target, model);
  }
}

//Get target value from step as power (convert if needed)
function powerValueFromStep(step, model){
  model = filledModel(model); //Fill model with default value to help conversion in case power or hr is empty
  if(step.target_unit === 'power'){
    switch(step.target_type){
      case 'threshold': return Math.round(step.target*model.threshold_power/100);
      case 'max': return Math.round(step.target*model.max_power/100);
      case 'anaerobic': return model.threshold_power + Math.round(step.target*model.anaerobic_capacity/(100*step.duration));
      case 'map': return Math.round(step.target*PowerModel.modelMAP(model)/100);
    }
  }else if(step.target_unit === 'heart_rate'){ //Power value equivalent for heart rate
    switch(step.target_type){
      case 'threshold': return Math.round(step.target*model.threshold_power/100);
      case 'max': return Math.round((step.target*model.max_heart_rate/model.threshold_heart_rate) * model.threshold_power/100);
    }
  }else{ //convert RPE to power
    return intensityUnitsConvert.RPEToPower(step.target, model);
  }
}

//Get target value from step as RPE (convert if needed)
function rpeValueFromStep(step, model){
  model = filledModel(model); //Fill model with default value to help conversion in case power or hr is empty
  if(step.target_unit === 'power'){
    return intensityUnitsConvert.powerToRPE(powerValueFromStep(step, model), model);
  }else if(step.target_unit === 'heart_rate'){
    return intensityUnitsConvert.heartRateToRPE(heartRateValueFromStep(step, model), model);
  }else{
    return step.target;
  }
}

//Calc a display value for step (height of step in graph)
function displayValueFromStep(step, model){
  if(model.threshold_power) //if power model convert to power
    return powerValueFromStep(step, model);
  else if(model.threshold_heart_rate) //if no power model but hr model, convert to hr
    return heartRateValueFromStep(step, model);
  else
    return rpeValueFromStep(step, model); //if no model convert to rpe
}

//Convert step to heart rate step,
//but for zone 6 and 7 will return a rpe target (only if hrCanConvertToRpe flag is true)
function convertStepHeartRate(step, model, hrCanConvertToRpe = true){
  if(step.target_unit === 'heart_rate')
    return { value: heartRateValueFromStep(step, model), target_unit: 'heart_rate' };
  return intensityUnitsConvert.powerToHeartRate(powerValueFromStep(step, model), model, hrCanConvertToRpe);
}

//Convert step to best unit according to model
//If step is power and there is a power model (or hr step with hr model, or rpe step) then no change.
//If step is power and no power model then convert to hr or rpe
//Same if step is hr and no hr model, convert to either power or rpe
function convertStepWithModel(step, model, preferredUnit = 'power', hrCanConvertToRpe = true){
  if(!['power', 'heart_rate', 'rpe'].includes(preferredUnit))
    preferredUnit = 'power';


  //If power model defined and it's a power step (or heart rate step if no heart rate model). Return step as power
  if(model && model.threshold_power && ((step.target_unit === 'power' && preferredUnit === 'power') || (step.target_unit === 'heart_rate' && !model.threshold_heart_rate)))
    return { value: powerValueFromStep(step, model), target_unit: 'power' };

  //If heart rate model defined and it's a heart rate step or a power step (if no power model) longer than 3min. return step as heart rate
  if(model && model.threshold_heart_rate && (step.target_unit === 'heart_rate' || (step.target_unit === 'power' && (step.duration >= 180 || !hrCanConvertToRpe)))
    && (preferredUnit === 'power' || preferredUnit === 'heart_rate'))
    return convertStepHeartRate(step, model, hrCanConvertToRpe);

  //Else return as rpe
  return { value: rpeValueFromStep(step, model), target_unit: 'rpe' };
}

//Convert step to array of power (with 1 power value for each second)
function powerValuesArray(workout, model){
  model = filledModel(model);
  var values = [];
  var steps = toSimpleSteps(workout.steps);
  for(var step of steps){
    var value = powerValueFromStep(step, model);
    Array.prototype.push.apply(values, Array(step.duration).fill(value));
  }
  return values;
}

//Calc normalized power of workout
//TODO: calc without converting workout to array which is not efficient
function calcNPower(workout, model){
  model = filledModel(model);
  var values = powerValuesArray(workout, model);
  let trueNP = activityUtil.calcNPower(values);
  return trueNP;
  /*console.log('True NP:', trueNP);
  var steps = toSimpleSteps(workout.steps);
  var sum = 0;
  for(var step of steps){
    var value = powerValueFromStep(step, model);
    sum += Math.pow(value, 4)*step.duration;
  }
  var np = Math.round(Math.pow(sum/workout.duration, 1/4));
  console.log('NP:', np);
  return np;*/
}

//Calc average power of workout
function averagePower(workout, model){
  model = filledModel(model);
  var sum = 0;
  var steps = toSimpleSteps(workout.steps);
  for(var step of steps){
    var value = powerValueFromStep(step, model);
    sum += value*step.duration;
  }
  return Math.round(sum/workout.duration);
}

//Power zone of step
function stepPowerZone(step, model){
  model = filledModel(model);
  var value = powerValueFromStep(step, model);
  return zones.powerZoneOfValue(value, model);
}

//Calc time in zones for workout
function timeInPowerZones(workout, model){
  model = filledModel(model);
  var tiz = [0,0,0,0,0,0,0];
  var powerZones = zones.powerZones(model.threshold_power);
  var steps = toSimpleSteps(workout.steps);
  for(var step of steps){
    var value = powerValueFromStep(step, model);
    let i=0;
    while(i < powerZones.length){
      if(value <= powerZones[i])
        break;
      i++;
    }
    tiz[i] += step.duration;
  }
  return tiz;
}

//Calc all secondary data for workout
//Normalized power, average power, intensity, training load, time in zones, calorries
function calcData(workout, model, fillModel = false){
  var data = workout.data || {};

  if(fillModel)
    model = filledModel(model);

  if(!model.threshold_power)
    return data;

  var np = calcNPower(workout, model);
  var avg_power = averagePower(workout, model);
  var calories = Math.round(avg_power * workout.duration/1000);
  var threshold = model.threshold_power || 0;
  var intensity = Math.round(np/threshold*100)/100;
  var load = Math.round((workout.duration/36)*intensity*intensity);
  var time_power_zones = timeInPowerZones(workout, model);

  return Object.assign(data, { np: np, intensity: intensity, load: load, avg_power: avg_power, calories: calories, time_power_zones: time_power_zones });
}

//Convert workout steps to a single list of simple steps
//All steps repeat are transformed into an array of steps repeated the number of times needed
function toSimpleSteps(steps){
  var result = [];
  for(var step of steps){
    if(step.type === 'STEP'){
      result.push(step);
    }else if(step.type === 'REPEAT'){
      var subSteps = toSimpleSteps(step.steps);
      result = result.concat(new Array(step.repeatitions).fill(subSteps).flat());
    }
  }

  return result;
}



//Check if the new model change the targets of the workout
function checkNewModelChangeWorkout(workout, previousModel, newModel){
  if(!workout.steps || workout.steps.length === 0)
    return false;
  return checkNewModelChangeSteps(workout.steps, previousModel, newModel);
}

//Check if the new model change any step target of the workout
function checkNewModelChangeSteps(steps, previousModel, newModel){
  for(let step of steps){
    let change = false;
    if(step.type === 'REPEAT'){ //On step repeat check sub steps
      change = checkNewModelChangeSteps(step.steps, previousModel, newModel);
    }else if(step.type === 'STEP'){ //On step check if target change
      let step1 = convertStepWithModel(step, previousModel);
      let step2 = convertStepWithModel(step, newModel);
      change = step1.value !== step2.value || step1.target_unit !== step2.target_unit;
    }
    if(change)
      return true;
  }
  return false; //No change detected on any step
}



module.exports = {
  valueFromStep,
  powerValueFromStep,
  displayValueFromStep,
  convertStepWithModel,
  stepPowerZone,
  calcData,
  toSimpleSteps,
  powerValuesArray,
  timeInPowerZones,
  checkNewModelChangeWorkout,
}
