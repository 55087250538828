<template>
  <div v-if="!trainingRide.bound_activity">
    <div class="trainingridebox is-relative" @click="showTrainingRide()" @contextmenu.prevent="$refs.trainingRideMenu.open">
      <span v-if="showButtons" class="pos-top-right is-clickable" @click.stop="$refs.trainingRideMenu.open"><b-icon icon="dots-vertical"/></span>
      <div class="hero has-background-danger-dark">
        <h4 class="title is-6 has-text-white">
          <b-icon v-if="trainingRide.sport=='cycling'" size="is-small" icon="bike"/>
          <b-icon v-if="trainingRide.sport=='running'" size="is-small" icon="run"/>
          {{ trainingRide.name }}
        </h4>
      </div>
      <div class="mt-1">
        <p v-if="trainingRide.estimated_duration || trainingRide.distance">
          {{ trainingRide.estimated_duration ? utimeToString(trainingRide.estimated_duration) : ''}}
          {{ trainingRide.distance ? showDistance(trainingRide.distance) : '' }}
        </p>
        <b-tag v-if="trainingRide.planned_intensity">{{ $t('metrics.'+trainingRide.planned_intensity) }}</b-tag>
      </div>

      <b-button @click.native="validate()" v-if="showButtons && trainingRide.date <= new Date() && !trainingRide.bound_activity &&
        (trainingRide.date.isThisWeek() || trainingRide.date.daysDiff(new Date()) < 3)"
        class="is-primary is-small w-100p">
          {{ $t('general.validate') }}
      </b-button>

    </div>



    <vue-context ref="trainingRideMenu">
      <li><a @click="edit()">{{ $t('general.edit') }}</a></li>
      <template v-if="!trainingRide.bound_activity">
        <li v-for="(activity, i) in ugetActivitiesForDay(trainingRide.date)" :key="i">
          <a v-if="!activity.bound_training_ride" @click="bindActivity(activity)">{{ $t('workout.bind_with') + activity.name }}</a>
        </li>
        <li v-if="trainingRide.date <= new Date()"><a @click="completed()">{{ $t('training_ride.mark_as_completed') }}</a></li>
      </template>
      <li><a @click="del()">{{ $t('general.delete') }}</a></li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context';

export default {
  name: 'trainingridebox',
  props: {
    trainingRide: { type: Object },
    showButtons: { type: Boolean, default: true},
  },
  methods: {
    showTrainingRide(){
    },

    del(){
      this.$store.dispatch('removeTrainingRide', this.trainingRide).then(() =>{
      }).catch(err => {
         console.error(err);
      });
    },

    edit(){
      this.$eventBus.$emit('show-modal-new-trainingride', { trainingRide: this.trainingRide });
    },

    bindActivity(activity){
      this.$store.dispatch('bindTrainingRideActivity', { training_ride: this.trainingRide, activity: activity }).then().catch(err => console.log(err));
    },

    completed(){
      let activity = {
        name: this.trainingRide.name,
        date: this.trainingRide.date,
        timestamp: this.trainingRide.date,
        duration: this.trainingRide.estimated_duration,
        description: this.trainingRide.description,
        type: this.trainingRide.sport,
        data: { distance: this.trainingRide.estimated_distance || 0, elevation: this.trainingRide.estimated_elevation },
      };
      this.$store.dispatch('newActivity', activity);
    },

    validate(){
      this.$eventBus.$emit('show-modal-validate-workout', { workout: this.trainingRide, type: 'TRAININGRIDE' });
    }
  },
  components: {
    VueContext
  }
}
</script>

<style lang="scss">
.trainingridebox{
  border-bottom: solid;
}
</style>
